import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FloatingUIElement from "./FloatingUIElement";

/**
 * Hero section for the home page
 */
const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallMobile, setIsSmallMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  // Check device size and update responsive states
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenWidth(width);
      setIsMobile(width <= 768);
      setIsSmallMobile(width <= 480);
    };

    // Check on initial render
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Add counter animation
  useEffect(() => {
    const targetCount = 600;
    const duration = 2000; // 2 seconds for the animation
    const startTime = Date.now();

    const animateCount = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < duration) {
        // Calculate the eased progress (starts slow, accelerates in the middle, slows at the end)
        const progress = elapsed / duration;
        const easedProgress =
          progress < 0.5
            ? 2 * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 2) / 2;

        // Calculate current count based on progress
        const currentCount = Math.floor(easedProgress * targetCount);
        setCount(currentCount);

        countRef.current = requestAnimationFrame(animateCount);
      } else {
        setCount(targetCount);
      }
    };

    countRef.current = requestAnimationFrame(animateCount);

    // Cleanup animation frame on unmount
    return () => {
      if (countRef.current) {
        cancelAnimationFrame(countRef.current);
      }
    };
  }, []);

  // Calculate responsive font size based on screen width
  const getResponsiveFontSize = (base, min) => {
    if (screenWidth <= 320) return Math.max(min - 0.05, 0.75); // Even smaller for tiny screens
    if (isSmallMobile) return min;
    if (isMobile) return Math.max(min, base * 0.8);
    return base;
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  // Calculate responsive padding based on screen size
  const getResponsivePadding = () => {
    if (screenWidth <= 320) return "10px 6px"; // Smaller padding for tiny screens
    if (screenWidth <= 375) return "12px 10px"; // Small phones (iPhone SE, etc)
    if (screenWidth <= 480) return "15px 12px"; // Medium phones
    if (isMobile) return "18px 16px"; // Large phones
    return "24px 24px"; // Tablets and above
  };

  // Add a scroll handler for the Learn More button
  const scrollToNextSection = () => {
    // For mobile, prioritize trusted-by-id as value-proposition-section is hidden
    const nextSection = isMobile
      ? document.getElementById("trusted-by-id")
      : (document.getElementById("value-proposition-section") || document.getElementById("trusted-by-id"));
    
    if (nextSection) {
      // Calculate the position to scroll to
      const navbarHeight = 80; // Adjust based on your navbar height
      const elementPosition =
        nextSection.getBoundingClientRect().top +
        window.scrollY - // Use window.scrollY for better browser compatibility
        navbarHeight;

      // Log for debugging
      console.log("Scrolling to position:", elementPosition);

      // Perform the smooth scroll
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    } else {
      console.warn("Could not find target section for scrolling");
    }
  };

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.8,
        ease: [0.22, 1, 0.36, 1], // Custom cubic-bezier for smooth slide
      }}
    >
      <div
        style={{
          background: "#000",
          position: "relative",
          width: "100%",
          padding: isMobile ? "0" : "0 5%",
          overflow: "hidden", // Prevent content overflow
        }}
      >
        {/* Background with subtle gradient - blue background removed */}
        <div
          className="hero-background"
          style={{
            margin: isMobile ? "0" : "0 auto",
            width: "100%", // Full width on mobile
            maxWidth: "100%", // Ensure no overflow
            borderRadius: isMobile ? "0px 0px 15px 15px" : "0px 0px 25px 25px",
            background: "transparent", // Override the blue background
            boxShadow: "none", // Remove the blue glow shadow
            overflow: "hidden", // Prevent content overflow
          }}
        >
          {/* Base noise layer - adjusted for visibility */}
          {!isMobile && <div className="noise-base" />}

          {/* Background elements */}
          {!isMobile && (
            <div className="hero-background-elements">
              {/* Floating UI elements with logos - with unique bobbing animations */}
              <FloatingUIElement
                position={{ top: "15%", left: "5%" }}
                logo="chatgpt.png"
                logoAlt="ChatGPT"
                size="80px"
                logoSize="60%"
                animationDuration={4}
                animationOffset={{
                  y: [0, 15, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />

              <FloatingUIElement
                position={{ bottom: "25%", left: "8%" }}
                logo="quizlet.png"
                logoAlt="Quizlet"
                size="90px"
                logoSize="65%"
                animationDuration={5.5}
                animationOffset={{
                  y: [0, 12, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />

              <FloatingUIElement
                position={{ top: "20%", right: "5%" }}
                logo="grammarly.png"
                logoAlt="Grammarly"
                size="100px"
                logoSize="70%"
                animationDuration={4.8}
                animationOffset={{
                  y: [0, 18, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />

              <FloatingUIElement
                position={{ bottom: "20%", right: "6%" }}
                logo="chegg.png"
                logoAlt="Chegg"
                size="70px"
                logoSize="60%"
                animationDuration={6.2}
                animationOffset={{
                  y: [0, 10, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />
            </div>
          )}

          <div
            className="stripe-hero-container"
            style={{
              position: "relative",
              zIndex: 5,
              padding: isMobile ? "15px 1% 20px" : "42px 5% 38px",
              maxWidth: "1200px",
              margin: "0 auto",
              overflow: "hidden", // Prevent content overflow
            }}
          >
            {/* Left side - Text content */}
            <motion.div
              className="stripe-hero-content"
              initial="hidden"
              animate="visible"
              variants={staggerContainer}
              style={{
                width: "100%",
                maxWidth: "100%",
                padding: isMobile ? "0" : 0,
                overflow: "visible",
              }}
            >
              {/* Updated text badge - hidden on mobile */}
              {!isMobile && (
                <div
                  className="users-badge"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    background: "transparent",
                    border: "1px solid rgba(255, 255, 255, 0.15)",
                    borderRadius: "100px",
                    padding: "6px 12px",
                    marginBottom: "10px",
                    maxWidth: "fit-content",
                  }}
                >
                  <div
                    style={{
                      marginRight: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                        stroke="#007CFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 2.44V12.42C17 14.39 15.59 15.16 13.86 14.12L12.54 13.33C12.24 13.15 11.76 13.15 11.46 13.33L10.14 14.12C8.41 15.15 7 14.39 7 12.42V2.44"
                        stroke="#007CFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span
                    style={{
                      fontWeight: 500,
                      color: "white",
                      fontSize: "0.9rem",
                    }}
                  >
                    Join hundreds of students making the switch
                  </span>
                </div>
              )}

              <motion.h1 
                variants={fadeInUp} 
                className="stripe-hero-title"
                style={{
                  fontSize: screenWidth <= 320 ? "38px" : (isSmallMobile ? "44px" : (isMobile ? "52px" : "72px")),
                  lineHeight: isSmallMobile ? "1.1" : "1.2", 
                  marginBottom: isSmallMobile ? "16px" : "25px",
                  marginTop: "0",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "100%",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                  padding: isMobile ? "0" : "0"
                }}
              >
                <span 
                  className="title-line" 
                  style={{ 
                    display: isMobile ? "block" : "inline",
                    marginBottom: isMobile ? "4px" : "0",
                    fontSize: screenWidth <= 320 ? "38px" : "inherit"
                  }}
                >
                  One App to
                </span>
                {!isMobile && " "}
                <span 
                  className="hero-highlight title-line" 
                  style={{ 
                    display: isMobile ? "block" : "inline",
                    fontSize: screenWidth <= 320 ? "36px" : (isSmallMobile ? "42px" : (isMobile ? "50px" : "72px")),
                  }}
                >
                  Replace Them All.
                </span>
              </motion.h1>

              <motion.p
                variants={fadeInUp}
                className="stripe-hero-description"
                style={{ 
                  fontSize: getResponsiveFontSize(1.1, 0.85) + "rem",
                  lineHeight: isMobile ? "1.4" : "1.5",
                  margin: isMobile ? "0 auto 22px" : "0 auto 36px",
                  maxWidth: isMobile ? "94%" : "780px",
                  display: isSmallMobile ? "none" : "block", // Hide on very small screens
                  textAlign: "center",
                  padding: "0"
                }}
              >
                Stop wasting your money on overpriced study subscriptions that
                limit you to only one tool. With our all-in-one suite of tools,
                you can finally make the most of your time and resources.
              </motion.p>

              {/* Completely redesigned CTA Buttons */}
              <motion.div
                variants={fadeInUp}
                className="stripe-hero-cta"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  gap: "14px",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "300px",
                  padding: "0",
                  margin: "0 auto 12px",
                  transform: "scale(1)"
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    display: "inline-block",
                  }}
                >
                  <Link
                    to="/download-app"
                    style={{
                      padding: "8px 14px",
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "#fff",
                      backgroundColor: "var(--color--button-color, #007CFF)",
                      borderRadius: "20px",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      fontWeight: "400",
                      boxShadow: "0 2px 10px rgba(0, 127, 255, 0.3), inset 0 2px 10px rgba(0, 0, 0, 0.6)",
                      backdropFilter: "blur(5px)",
                      WebkitBackdropFilter: "blur(5px)",
                      transition: "all 0.4s ease, transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275)"
                    }}
                  >
                    Get Started
                    <RocketLaunchIcon
                      sx={{
                        ml: 0.5,
                        fontSize: "14px",
                        color: "white"
                      }}
                    />
                  </Link>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    display: "inline-block",
                  }}
                >
                  <a
                    href="#trusted-by-id"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToNextSection();
                    }}
                    style={{
                      padding: "8px 14px",
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "var(--color--heading-text-color, #fff)",
                      backgroundColor: "var(--color--hoved-color, rgba(255, 255, 255, 0.1))",
                      borderRadius: "20px",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "0.5px solid rgba(255, 255, 255, 0.1)",
                      borderBottomStyle: "none",
                      fontWeight: "400",
                      boxShadow: "inset 0 2.5px 0 -2px rgba(255, 255, 255, 0.1)",
                      transition: "all 0.4s ease, transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275)"
                    }}
                  >
                    Learn More
                    <ArrowForwardIcon
                      sx={{
                        ml: 0.5,
                        fontSize: "14px",
                        color: "inherit"
                      }}
                    />
                  </a>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeroSection;
