import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import "./css/App.css";
import "./css/normalize.css";
import "./css/webflow.css";
import "./css/edutools.webflow.css";
import NotFound from "./pages/NotFound";
import Cancel from "./pages/Cancel";
import DownloadApp from "./pages/DownloadApp";
import Failure from "./pages/Failure";
import Home from "./pages/Home";
import HyperLinks from "./pages/HyperLinks";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";
import Success from "./pages/Success";
import Login from "./pages/Login";
import LoginSuccess from "./pages/LoginSuccess";
import Info from "./pages/Info";
import { applyPerformanceOptimizations, optimizeImages } from "./utils/performanceUtils";

function App() {
  // Apply performance optimizations when the app loads
  useEffect(() => {
    // Apply device-specific optimizations
    applyPerformanceOptimizations();
    
    // Optimize images after the page has loaded
    window.addEventListener('load', optimizeImages);
    
    return () => {
      window.removeEventListener('load', optimizeImages);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login-success" element={<LoginSuccess />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/download-app" element={<DownloadApp />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/success" element={<Success />} />
          <Route path="/qr-3tV8jZ9" element={<Info />} />
          <Route path="/123hyperlinks123" element={<HyperLinks />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
