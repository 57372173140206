import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import MobilePricingSection from './MobilePricingSection';
import checkIcon from '../../images/Check.svg';

/**
 * Section that displays pricing plans
 */
const PricingSection = ({ pricingPlans }) => {
  return (
    <div id="pricing-section" className="section">
      <div className="container">
        {/* Desktop Pricing View */}
        <div className="desktop-pricing">
          <div className="section-paddings">
            <div className="section-center-text">
              <div className="section-title">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: false }}
                  transition={{ duration: 0.7, ease: "easeOut" }}
                >
                  <h2 className="title medium">
                    <strong>Pricing Plans</strong>
                  </h2>
                </motion.div>
              </div>
              <div className="section-paragraph">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: false }}
                  transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
                >
                  <p
                    className="paragraph-2"
                    style={{
                      fontSize: "14px",
                      color: "#999",
                      fontStyle: "italic",
                      lineHeight: "1.5",
                      maxWidth: "600px",
                      margin: "0 auto",
                    }}
                  >
                    *Prices will increase in the coming months. Rest assured, the price you
                    buy at will be what you pay regardless of any updates to
                    pricing in the future. Contact us with any questions.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="pricing-wrapper">
              <div className="pricing-list-wrapper w-dyn-list">
                <div className="pricing-grid w-dyn-items">
                  {pricingPlans.map((plan, index) => (
                    <motion.div
                      initial={{ opacity: 0, y: 30 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: false }}
                      transition={{ 
                        duration: 0.6, 
                        delay: index * 0.15,
                        ease: "easeOut" 
                      }}
                      role="listitem"
                      className={`pricing-list-item w-dyn-item ${
                        plan.plan === "Monthly" ? "middle" : ""
                      }`}
                      key={plan.id}
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                        opacity: 0.85, // Decreased opacity for all cards
                        display: plan.plan === "Free" ? "block" : "block",
                      }}
                    >
                      <div
                        className={`pricing ${
                          plan.plan === "Monthly" ? "middle" : ""
                        }`}
                        style={{
                          border: plan.plan === "Monthly" 
                            ? "2px solid rgba(0, 98, 255, 0.7)" 
                            : "1px solid rgba(255, 255, 255, 0.102)",
                          animation: plan.plan === "Monthly" 
                            ? "pulseBorder 2s infinite" 
                            : "none",
                          boxShadow: plan.plan === "Monthly"
                            ? "0 0 15px rgba(0, 98, 255, 0.3)"
                            : "none"
                        }}
                      >
                        <div
                          className={`pricing-header ${
                            plan.plan === "Monthly" ? "middle" : ""
                          }`}
                        >
                          <div className="price-content">
                            <div className="price-plan">{plan.plan}</div>
                            <div className="price-type">
                              {plan.billingType}
                            </div>
                          </div>
                          <div className="price">
                            {plan.originalPrice ? (
                              <>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    fontSize: "0.7em",
                                    color: "#999",
                                    marginRight: "8px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {plan.originalPrice}
                                </span>
                                {plan.price}
                              </>
                            ) : (
                              plan.price
                            )}
                          </div>
                        </div>
                        <div className="pricing-button-holder">
                          <Link
                            to="/download-app"
                            className={
                              plan.plan === "Monthly"
                                ? "button"
                                : plan.button.className
                            }
                          >
                            {plan.button.text}
                          </Link>
                        </div>
                        <div className="list-holder">
                          <ul className="w-list-unstyled">
                            {plan.features.map((feature, idx) => (
                              <li
                                className="list-item"
                                key={idx}
                                style={{
                                  transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                  transformStyle: "preserve-3d",
                                  opacity: 1,
                                }}
                              >
                                <div
                                  className={`check-icon-holder ${
                                    plan.plan === "Monthly" ? "" : "outlined"
                                  }`}
                                >
                                  <img
                                    src={checkIcon}
                                    loading="lazy"
                                    alt="Check Icon"
                                    className="check-icon"
                                  />
                                </div>
                                <div className="check-item-text">
                                  {feature}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Pricing View */}
        <div className="mobile-pricing-only">
          <MobilePricingSection pricingPlans={pricingPlans} />
        </div>
      </div>
      
      {/* Add keyframe animation for the pulsing border */}
      <style jsx="true">{`
        @keyframes pulseBorder {
          0% { box-shadow: 0 0 15px rgba(0, 98, 255, 0.3); }
          50% { box-shadow: 0 0 25px rgba(0, 98, 255, 0.7); }
          100% { box-shadow: 0 0 15px rgba(0, 98, 255, 0.3); }
        }
      `}</style>
    </div>
  );
};

export default PricingSection; 