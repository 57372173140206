import React from 'react';
import { motion } from 'framer-motion';
import FAQItem from './FAQItem';

/**
 * Section that displays frequently asked questions
 */
const FAQSection = ({ faqData, openIndexes, toggleFAQ }) => {
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: window.innerWidth < 768 ? 0.1 : 0.2,
        delayChildren: 0,
      },
    },
  };

  return (
    <div className="section" id="faq-section">
      <div className={window.innerWidth <= 768 ? "" : "container"} style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "60px" }}>
        <div className="section-paddings">
          <div className="section-center-text" data-aos="fade-up">
            <div className="section-title">
              <div className="fade-in-on-scroll">
                <h2 className="title medium">
                  <strong>Frequently asked Questions</strong>
                </h2>
              </div>
            </div>
            <div className="section-paragraph">
              <div className="fade-in-on-scroll">
                <p>Everything you need to know</p>
              </div>
            </div>
          </div>
          <motion.div
            className="questions-holder"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-50px" }}
            variants={staggerContainer}
            style={{
              gap: '8px',
              '@media (max-width: 768px)': {
                gap: '6px'
              },
              willChange: 'opacity',
              transform: 'translateZ(0)'
            }}
          >
            {faqData.map((faq, index) => (
              <FAQItem 
                key={index}
                faq={faq}
                index={index}
                isOpen={openIndexes.includes(index)}
                toggleFAQ={toggleFAQ}
              />
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection; 