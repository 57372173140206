import React from 'react';
import { Box, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import TestimonialCard from './TestimonialCard';

/**
 * Testimonials section that displays user reviews
 */
const TestimonialsSection = ({ testimonials }) => {
  return (
    <div id="review-section" className="section overflow-hidden" style={{ 
      paddingTop: 0,
      marginTop: "-20px"
    }}>
      <div className="container">
        <div className="section-paddings" style={{ paddingTop: "10px" }}>
          <div className="section-center-text">
            <div className="section-title">
              <div>
                <h2 className="title medium">
                  <strong>
                    Loved by Students{" "}
                    <span className="section-highlight">Like You</span>
                  </strong>
                </h2>
              </div>
            </div>
            <div className="section-paragraph">
              <div>
                <p>
                  Hear what students have to say about
                  Edutools!
                </p>
              </div>
            </div>
          </div>

          {/* Testimonials Grid */}
          <Box sx={{ py: { sm: 3, md: 8 } }}>
            <Grid container spacing={3} direction="column">
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} key={testimonial.id}>
                  <TestimonialCard 
                    testimonial={testimonial} 
                    index={index} 
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection; 