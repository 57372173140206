/**
 * Utility functions for performance optimization
 * Helps detect low-end devices and adjust animations accordingly
 */

/**
 * Detects if the current device is likely to have performance issues
 * Uses a combination of device memory, hardware concurrency, and user agent detection
 * @returns {boolean} True if the device is likely a low-end device
 */
export const isLowEndDevice = () => {
  // Check if we're on a mobile device
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  
  // Check available memory (only available in Chrome)
  const lowMemory = navigator.deviceMemory && navigator.deviceMemory < 4;
  
  // Check CPU cores
  const lowCPU = navigator.hardwareConcurrency && navigator.hardwareConcurrency < 4;
  
  // Check if we're on an older Android device
  const isOldAndroid = /Android\s[0-6]/i.test(navigator.userAgent);
  
  // Check if we're on an older iOS device
  const isOldiOS = /iPhone OS ([1-9]|1[0-2])_/i.test(navigator.userAgent);
  
  return (isMobile && (lowMemory || lowCPU || isOldAndroid || isOldiOS));
};

/**
 * Applies performance optimizations to the document based on device capabilities
 */
export const applyPerformanceOptimizations = () => {
  // Check if we're dealing with a low-end device
  if (isLowEndDevice()) {
    // Add a class to the body for CSS optimizations
    document.body.classList.add('low-end-device');
    
    // Optimize AOS animations if available
    if (window.AOS) {
      window.AOS.init({
        disable: false, // Don't completely disable, rely on CSS optimizations
        once: true, // Only animate once
        duration: 400, // Faster animations
        delay: 0, // No delays
        mirror: false // No mirroring
      });
    }
    
    // Apply optimizations for Framer Motion if using it
    window.MOTION_REDUCE_ANIMATIONS = true;
  }
};

/**
 * Optimizes image rendering on the page
 * - Reduces image quality on low-end devices
 * - Delays loading of offscreen images
 */
export const optimizeImages = () => {
  if (isLowEndDevice()) {
    // Find all images that aren't critical
    const nonCriticalImages = document.querySelectorAll('img:not(.critical-image)');
    
    // Add loading="lazy" to all non-critical images
    nonCriticalImages.forEach(img => {
      img.loading = 'lazy';
      
      // Also reduce quality for non-critical images by using a smaller version if available
      const src = img.getAttribute('src');
      if (src && src.includes('?')) {
        img.setAttribute('src', `${src}&q=70&w=${Math.round(img.width * 0.8)}`);
      } else if (src) {
        img.setAttribute('src', `${src}?q=70&w=${Math.round(img.width * 0.8)}`);
      }
    });
  }
}; 