import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoIosArrowDown } from 'react-icons/io';

/**
 * A component that displays an FAQ item with toggle functionality
 */
const FAQItem = ({ faq, index, isOpen, toggleFAQ }) => {
  return (
    <motion.div
      className={`questions-container ${isOpen ? "open" : ""}`}
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: {
          opacity: 1,
          y: 0,
          transition: { 
            duration: 0.4,
            ease: "easeOut" 
          },
        },
      }}
      style={{
        marginBottom: '10px',
        transform: 'translateZ(0)',
        willChange: 'opacity, transform'
      }}
    >
      <div 
        className="question" 
        onClick={() => toggleFAQ(index)}
        style={{
          padding: '10px 15px',
          '@media (max-width: 768px)': {
            padding: '8px 12px'
          }
        }}
      >
        <div className="question-text">
          {faq.question}
        </div>
        <IoIosArrowDown
          className={`question-icon ${isOpen ? "rotate" : ""}`}
          size={20}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="answer-holder"
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.2 }
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.1 },
              },
            }}
            style={{
              overflow: "hidden",
              padding: "15px 0 0 0",
              transform: 'translateZ(0)',
              willChange: 'height, opacity'
            }}
          >
            <div
              className="answer-text"
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            ></div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default FAQItem; 