import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import checkIcon from '../../images/Check.svg';

/**
 * A component that displays pricing plans for mobile devices
 */
const MobilePricingSection = ({ pricingPlans }) => {
  // Only include the Monthly and Semesterly plans (indices 1 and 2)
  const mobilePlans = pricingPlans.filter((plan, index) => index > 0);

  // Animation variants for staggered animation
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  const cardVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5
      }
    },
  };

  return (
    <div >
      <div >
        <div >
          <div className="section-center-text">
            <div className="section-title">
              <div data-aos="fade-up" className="fade-in-on-scroll">
                <h2 className="title medium">
                  <strong>Pricing Plans</strong>
                </h2>
              </div>
            </div>
            <div className="section-paragraph">
              <div data-aos="fade-up" className="fade-in-on-scroll">
                <p
                  className="paragraph-2"
                  style={{
                    fontSize: "14px",
                    color: "#999",
                    fontStyle: "italic",
                    padding: "0 15px",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  *Prices will increase in the coming months. Rest assured, the price you buy at
                  will be what you pay regardless of any increases to pricing in
                  the future. Contact us with any questions.
                </p>
              </div>
            </div>
          </div>

          <motion.div
            className="mobile-pricing-wrapper"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, margin: "-100px" }}
            variants={staggerContainer}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              margin: "20px auto",
              maxWidth: "400px",
              padding: "0 15px",
            }}
          >
            {mobilePlans.map((plan, index) => (
              <motion.div
                key={plan.id}
                variants={cardVariant}
                className={`mobile-pricing-card`}
                style={{
                  width: "100%",
                  background:
                    "radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "16px",
                  border: "1px solid rgba(255, 255, 255, 0.102)",
                  padding: "20px",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease",
                }}
              >
                <div className="price-header" style={{ marginBottom: "12px" }}>
                  <div
                    className="price-plan"
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                      color: "#fff",
                    }}
                  >
                    {plan.plan}
                  </div>
                  {plan.billingType && (
                    <div
                      className="price-type"
                      style={{
                        fontSize: "14px",
                        color: "#999",
                        marginTop: "4px",
                      }}
                    >
                      {plan.billingType}
                    </div>
                  )}
                </div>

                <div
                  className="price"
                  style={{
                    fontSize: "36px",
                    fontWeight: "bold",
                    color: "#fff",
                    marginBottom: "18px",
                  }}
                >
                  {plan.originalPrice ? (
                    <>
                      <span
                        style={{
                          textDecoration: "line-through",
                          fontSize: "0.7em",
                          color: "#999",
                          marginRight: "8px",
                          fontWeight: "normal",
                        }}
                      >
                        {plan.originalPrice}
                      </span>
                      {plan.price}
                    </>
                  ) : (
                    plan.price
                  )}
                </div>

                <div className="feature-list" style={{ marginBottom: "20px" }}>
                  <ul
                    style={{
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {plan.features.map((feature, idx) => (
                      <li
                        key={idx}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: "12px",
                          color: "#fff",
                        }}
                      >
                        <div
                          style={{
                            minWidth: "24px",
                            height: "24px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "12px",
                            background:
                              plan.plan === "Monthly"
                                ? "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)"
                                : "transparent",
                            border:
                              plan.plan === "Monthly"
                                ? "none"
                                : "1px solid rgba(255, 255, 255, 0.2)",
                          }}
                        >
                          <img
                            src={checkIcon}
                            alt="Check"
                            style={{ width: "14px", height: "14px" }}
                          />
                        </div>
                        <span style={{ lineHeight: "1.4" }}>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <Link
                  to="/download-app"
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "16px 0",
                    textAlign: "center",
                    borderRadius: "12px",
                    fontWeight: "600",
                    fontSize: "16px",
                    background:
                      plan.plan === "Monthly"
                        ? "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)"
                        : "transparent",
                    border:
                      plan.plan === "Monthly"
                        ? "none"
                        : "1px solid rgba(255, 255, 255, 0.2)",
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  {plan.button.text}
                </Link>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default MobilePricingSection; 