import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import appleIcon from "../images/apple.png";
import windowsIcon from "../images/window.png";
import { Snackbar, Alert } from "@mui/material";

const repoOwner = "nuhbeel";
const repoName = "EduTools";

const DownloadApp = () => {
  // State to track if user is on a mobile device
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Add new state for button hover effects
  const [macButtonHover, setMacButtonHover] = useState(false);
  const [windowsButtonHover, setWindowsButtonHover] = useState(false);
  const [copyButtonHover, setCopyButtonHover] = useState(false);

  // Add new state for download animation
  const [downloadingMac, setDownloadingMac] = useState(false);
  const [downloadingWindows, setDownloadingWindows] = useState(false);

  // Detect mobile device on component mount
  useEffect(() => {
    const detectMobileDevice = () => {
      // Check for mobile user agent
      const mobileRegex =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

      // Check for touch capability
      const hasTouchScreen =
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

      // Check screen size
      const isSmallScreen = window.innerWidth < 768;

      // Consider it a mobile device if the user agent matches AND it has touch OR small screen
      return (
        mobileRegex.test(navigator.userAgent) &&
        (hasTouchScreen || isSmallScreen)
      );
    };

    setIsMobileDevice(detectMobileDevice());
  }, []);

  // Mac download function for Apple Silicon (ARM64) Macs
  const handleMacDownload = async () => {
    setDownloadingMac(true);

    try {
      const response = await fetch(
        `https://api.github.com/repos/${repoOwner}/${repoName}/releases/latest`
      );
      const data = await response.json();

      if (!data.assets) {
        console.error("No assets found in the latest release.");
        setDownloadingMac(false);
        return;
      }

      let assetUrl = "";

      // Look for DMG file with arm64 in the name (Apple Silicon)
      for (let asset of data.assets) {
        if (
          asset.name.toLowerCase().endsWith(".dmg") &&
          asset.name.toLowerCase().includes("arm64")
        ) {
          assetUrl = asset.browser_download_url;
          break;
        }
      }

      // If no arm64 version found, fall back to any DMG file
      if (!assetUrl) {
        for (let asset of data.assets) {
          if (asset.name.toLowerCase().endsWith(".dmg")) {
            assetUrl = asset.browser_download_url;
            break;
          }
        }
      }

      if (assetUrl) {
        // Add a slight delay to show the animation
        setTimeout(() => {
          window.location.href = assetUrl;
          setDownloadingMac(false);
        }, 1500);
      } else {
        console.error("No suitable Mac version found.");
        setDownloadingMac(false);
      }
    } catch (error) {
      console.error("Error fetching release info:", error);
      setDownloadingMac(false);
    }
  };

  // Intel-based Mac download function
  const handleIntelMacDownload = async () => {
    setDownloadingMac(true);

    try {
      const response = await fetch(
        `https://api.github.com/repos/${repoOwner}/${repoName}/releases/latest`
      );
      const data = await response.json();

      if (!data.assets) {
        console.error("No assets found in the latest release.");
        setDownloadingMac(false);
        return;
      }

      let assetUrl = "";

      // Look for DMG file WITHOUT arm64 in the name (Intel)
      for (let asset of data.assets) {
        if (
          asset.name.toLowerCase().endsWith(".dmg") &&
          !asset.name.toLowerCase().includes("arm64")
        ) {
          assetUrl = asset.browser_download_url;
          break;
        }
      }

      // If no non-arm64 version found, fall back to any DMG file
      if (!assetUrl) {
        for (let asset of data.assets) {
          if (asset.name.toLowerCase().endsWith(".dmg")) {
            assetUrl = asset.browser_download_url;
            break;
          }
        }
      }

      if (assetUrl) {
        // Add a slight delay to show the animation
        setTimeout(() => {
          window.location.href = assetUrl;
          setDownloadingMac(false);
        }, 1500);
      } else {
        console.error("No suitable Intel Mac version found.");
        setDownloadingMac(false);
      }
    } catch (error) {
      console.error("Error fetching release info:", error);
      setDownloadingMac(false);
    }
  };

  const handleWindowsDownload = async () => {
    setDownloadingWindows(true);

    try {
      const response = await fetch(
        `https://api.github.com/repos/${repoOwner}/${repoName}/releases/latest`
      );
      const data = await response.json();

      if (!data.assets) {
        console.error("No assets found in the latest release.");
        setDownloadingWindows(false);
        return;
      }

      let assetUrl = "";

      for (let asset of data.assets) {
        if (asset.name.endsWith(".exe")) {
          assetUrl = asset.browser_download_url;
          break;
        }
      }

      if (assetUrl) {
        // Add a slight delay to show the animation
        setTimeout(() => {
          window.location.href = assetUrl;
          setDownloadingWindows(false);
        }, 1500);
      } else {
        console.error("No Windows .exe file found in the latest release.");
        setDownloadingWindows(false);
      }
    } catch (error) {
      console.error("Error fetching release info:", error);
      setDownloadingWindows(false);
    }
  };

  // Function to copy current URL to clipboard for mobile users
  const copyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Navbar />
      <div
        className="section"
        style={{
          minHeight: "calc(100vh - 80px)",
          display: "flex",
          alignItems: "center",
          padding: "60px 0",
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: "1000px",
            margin: "0 auto",
            padding: "0 20px",
          }}
        >
          <div className="download-page">
            {/* Simple header */}
            <h1
              style={{
                fontSize: "48px",
                textAlign: "center",
                marginBottom: "40px",
                background: "linear-gradient(135deg, #fff 0%, #e0e0e0 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Download EduTools
            </h1>

            {isMobileDevice ? (
              // Mobile device content
              <div
                style={{
                  maxWidth: "600px",
                  margin: "0 auto",
                  padding: "30px",
                  background:
                    "radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "16px",
                  border: "1px solid rgba(255, 255, 255, 0.102)",
                  textAlign: "center",
                  boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src={appleIcon}
                    alt="Mac"
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                  <div
                    style={{
                      margin: "0 15px",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#0062ff",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      background: "rgba(0, 98, 255, 0.1)",
                    }}
                  >
                    +
                  </div>
                  <img
                    src={windowsIcon}
                    alt="Windows"
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </div>
                <h2
                  style={{
                    fontSize: "24px",
                    marginBottom: "20px",
                    color: "white",
                  }}
                >
                  EduTools Desktop App
                </h2>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "1.6",
                    color: "#e0e0e0",
                    marginBottom: "24px",
                  }}
                >
                  EduTools is a desktop application. If you're viewing this on a
                  mobile device, please open the download page on your computer
                  to continue.
                </p>
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <button
                    onClick={copyLinkToClipboard}
                    onMouseEnter={() => setCopyButtonHover(true)}
                    onMouseLeave={() => setCopyButtonHover(false)}
                    style={{
                      background:
                        "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)",
                      border: "none",
                      borderRadius: "12px",
                      color: "white",
                      padding: "14px 28px",
                      fontSize: "16px",
                      fontWeight: "600",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      boxShadow: copyButtonHover
                        ? "0 0 15px rgba(0, 98, 255, 0.8), 0 0 30px rgba(0, 198, 255, 0.4)"
                        : "0 4px 20px rgba(0, 98, 255, 0.3)",
                      transform: copyButtonHover
                        ? "translateY(-2px)"
                        : "translateY(0)",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        zIndex: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Copy Link to Clipboard
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: "-100%",
                        width: "100%",
                        height: "100%",
                        background:
                          "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)",
                        animation: copyButtonHover
                          ? "shine 1.5s infinite"
                          : "none",
                      }}
                    />
                  </button>
                </div>
              </div>
            ) : (
              // Desktop content - download options
              <>
                {/* Disclaimer - desktop only */}
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#e0e0e0",
                    maxWidth: "600px",
                    margin: "0 auto 40px",
                    opacity: 0.8,
                  }}
                >
                  Note: EduTools is a desktop application. If you're
                  viewing this on a mobile device, please open this page on your
                  computer to download.
                </p>

                {/* Clean download options container */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "40px",
                    marginBottom: "60px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Mac download option */}
                  <div
                    style={{
                      width: "280px",
                      background:
                        "radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "16px",
                      border: "1px solid rgba(255, 255, 255, 0.102)",
                      padding: "30px",
                      textAlign: "center",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      transform: macButtonHover
                        ? "translateY(-5px)"
                        : "translateY(0)",
                      boxShadow: macButtonHover
                        ? "0 10px 30px rgba(0, 98, 255, 0.2), 0 0 15px rgba(0, 198, 255, 0.2)"
                        : "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    onMouseEnter={() => setMacButtonHover(true)}
                    onMouseLeave={() => setMacButtonHover(false)}
                  >
                    {macButtonHover && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background:
                            "radial-gradient(circle at center, rgba(0, 98, 255, 0.1) 0%, transparent 70%)",
                          zIndex: 0,
                        }}
                      />
                    )}
                    <img
                      src={appleIcon}
                      alt="Mac"
                      style={{
                        width: "80px",
                        height: "80px",
                        marginBottom: "20px",
                        filter: macButtonHover
                          ? "drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
                          : "none",
                        transition: "filter 0.3s ease",
                        position: "relative",
                        zIndex: 1,
                      }}
                    />
                    <h2
                      style={{
                        fontSize: "28px",
                        marginBottom: "25px",
                        color: "white",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      Mac
                    </h2>
                    <button
                      onClick={handleMacDownload}
                      disabled={downloadingMac}
                      style={{
                        background:
                          "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)",
                        border: "none",
                        borderRadius: "12px",
                        color: "white",
                        padding: "14px 28px",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: downloadingMac ? "default" : "pointer",
                        transition: "all 0.3s ease",
                        width: "100%",
                        boxShadow: macButtonHover
                          ? "0 0 15px rgba(0, 98, 255, 0.8), 0 0 30px rgba(0, 198, 255, 0.4)"
                          : "0 4px 20px rgba(0, 98, 255, 0.3)",
                        position: "relative",
                        overflow: "hidden",
                        zIndex: 1,
                      }}
                    >
                      <span
                        style={{
                          position: "relative",
                          zIndex: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                        }}
                      >
                        {downloadingMac ? (
                          <>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ animation: "spin 1s linear infinite" }}
                            >
                              <path
                                d="M12 2V6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.5"
                                d="M12 18V22"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.2"
                                d="M4.93 4.93L7.76 7.76"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.6"
                                d="M16.24 16.24L19.07 19.07"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.3"
                                d="M2 12H6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.7"
                                d="M18 12H22"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.4"
                                d="M4.93 19.07L7.76 16.24"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.8"
                                d="M16.24 7.76L19.07 4.93"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Downloading...
                          </>
                        ) : (
                          <>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 15L12 3"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16 11L12 15L8 11"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 15V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 15"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Download
                          </>
                        )}
                      </span>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "-100%",
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)",
                          animation: macButtonHover
                            ? "shine 1.5s infinite"
                            : "none",
                        }}
                      />
                    </button>
                    <p
                      onClick={!downloadingMac ? handleIntelMacDownload : null}
                      style={{
                        fontSize: "12px",
                        color: macButtonHover ? "#ffffff" : "#cccccc",
                        marginTop: "12px",
                        cursor: downloadingMac ? "default" : "pointer",
                        textDecoration: "underline",
                        transition: "color 0.3s ease",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      trouble downloading? click here
                    </p>
                  </div>

                  {/* Windows download option */}
                  <div
                    style={{
                      width: "280px",
                      background:
                        "radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "16px",
                      border: "1px solid rgba(255, 255, 255, 0.102)",
                      padding: "30px",
                      textAlign: "center",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      transform: windowsButtonHover
                        ? "translateY(-5px)"
                        : "translateY(0)",
                      boxShadow: windowsButtonHover
                        ? "0 10px 30px rgba(0, 98, 255, 0.2), 0 0 15px rgba(0, 198, 255, 0.2)"
                        : "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    onMouseEnter={() => setWindowsButtonHover(true)}
                    onMouseLeave={() => setWindowsButtonHover(false)}
                  >
                    {windowsButtonHover && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background:
                            "radial-gradient(circle at center, rgba(0, 98, 255, 0.1) 0%, transparent 70%)",
                          zIndex: 0,
                        }}
                      />
                    )}
                    <img
                      src={windowsIcon}
                      alt="Windows"
                      style={{
                        width: "80px",
                        height: "80px",
                        marginBottom: "20px",
                        filter: windowsButtonHover
                          ? "drop-shadow(0 0 8px rgba(255, 255, 255, 0.5))"
                          : "none",
                        transition: "filter 0.3s ease",
                        position: "relative",
                        zIndex: 1,
                      }}
                    />
                    <h2
                      style={{
                        fontSize: "28px",
                        marginBottom: "25px",
                        color: "white",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      Windows
                    </h2>
                    <button
                      onClick={handleWindowsDownload}
                      disabled={downloadingWindows}
                      style={{
                        background:
                          "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)",
                        border: "none",
                        borderRadius: "12px",
                        color: "white",
                        padding: "14px 28px",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: downloadingWindows ? "default" : "pointer",
                        transition: "all 0.3s ease",
                        width: "100%",
                        boxShadow: windowsButtonHover
                          ? "0 0 15px rgba(0, 98, 255, 0.8), 0 0 30px rgba(0, 198, 255, 0.4)"
                          : "0 4px 20px rgba(0, 98, 255, 0.3)",
                        position: "relative",
                        overflow: "hidden",
                        zIndex: 1,
                      }}
                    >
                      <span
                        style={{
                          position: "relative",
                          zIndex: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                        }}
                      >
                        {downloadingWindows ? (
                          <>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ animation: "spin 1s linear infinite" }}
                            >
                              <path
                                d="M12 2V6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.5"
                                d="M12 18V22"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.2"
                                d="M4.93 4.93L7.76 7.76"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.6"
                                d="M16.24 16.24L19.07 19.07"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.3"
                                d="M2 12H6"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.7"
                                d="M18 12H22"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.4"
                                d="M4.93 19.07L7.76 16.24"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                opacity="0.8"
                                d="M16.24 7.76L19.07 4.93"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Downloading...
                          </>
                        ) : (
                          <>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 15L12 3"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16 11L12 15L8 11"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 15V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 15"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Download
                          </>
                        )}
                      </span>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "-100%",
                          width: "100%",
                          height: "100%",
                          background:
                            "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)",
                          animation: windowsButtonHover
                            ? "shine 1.5s infinite"
                            : "none",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Add Snackbar component at the end */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            bgcolor: "rgba(0, 98, 255, 0.9)",
            color: "white",
            "& .MuiAlert-icon": {
              color: "white",
            },
          }}
        >
          Link copied to clipboard!
        </Alert>
      </Snackbar>

      {/* Add global styles for animations */}
      <style jsx global>{`
        @keyframes shine {
          0% {
            left: -100%;
          }
          100% {
            left: 100%;
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default DownloadApp;
